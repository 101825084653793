/* eslint-disable no-bitwise */
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    c => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    }
  )
}

/**
 * It encrypts the string to base64
 * @param {String} stringToEncrypt
 * @returns
 */
function encryptString(stringToEncrypt) {
  return Buffer.from(stringToEncrypt).toString('base64')
}

module.exports = {
  uuidv4,
  encryptString
}
