<template>
  <b-dropdown
    class="dropdownFloors"
    variant="primary"
    toggle-class="buttonToggle rounded"
  >
    <template #button-content>
      {{ currentFloor.name }}
    </template>
    <b-dropdown-header class="header">
      {{ $t('selectFloor') }}
    </b-dropdown-header>
    <b-dropdown-item-button
      v-for="(floor, idx) in floors"
      :key="`${floor.id}__${idx}`"
      :button-class="[
        'floor',
        {
          floorSelected: currentFloor.id === floor.id
        }
      ]"
      @click="onFloorSelection(floor)"
    >
      {{ floor.name }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  name: 'FloorsDropdown',
  props: {
    floors: {
      type: Array,
      default: () => []
    },
    currentFloor: {
      type: Object,
      default: () => {}
    },
    onFloorSelection: {
      type: Function,
      default() {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/globals/vars.scss';
@import '../../assets/scss/globals/mixins.scss';

.dropdownFloors {
  margin-left: auto;
  margin-right: 1rem;

  /deep/ .dropdown-menu {
    min-width: 100%;
  }

  &.show /deep/ .buttonToggle {
    background-color: transparent;
    color: $basecolor;
    border-color: $basecolor;
  }

  &.show /deep/ .buttonToggle:hover {
    color: white;
  }

  .header {
    margin-bottom: 0.5rem;
    text-align: center;
    border-bottom: 1px solid $bordercolor;
  }

  /deep/ .floor:hover {
    border-radius: 0;
  }

  /deep/ .floorSelected {
    background-color: $basecolor;
    color: white;
    border-radius: 0;
  }

  @include r(767) {
    margin: 0;
  }
}

/deep/ .dropdown-menu:before {
  border-width: 0;
}
</style>
